import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    sectionContainer,
    grid,
    titleText,
    subtitleText,
    contentText,
    formContainer,
    form,
    image,
    contactInfoWrapper,
    contactInfoBlock,
    successInfoContainer,
    successTitle,
    successContent,
    contactLink,
    heartIcon,
} from './contact.module.scss';

import { ISection } from '../../models/section.model';
import { ISocialLink } from '../../models/social-link.model';
import { IContactInfo } from '../../models/contact-info.model';
import { contactForm } from '../../formik/contact.form';
import { postContactForm } from '../../api-clients/contacts';
import usePostForm from '../../hooks/use-post-form';
import useTranslations from '../../hooks/use-translations';
import Heart from '../../assets/images/svg/heart.svg';

import Section from '../hoc/section';
import ContactInfo from '../molecules/contact-info';
import FormGenerator from '../molecules/form-generator';
import Title from '../atoms/title';

interface IContactSection extends ISection {
    content: ISection['content'] & Record<'contactInfo', IContactInfo[]>;
    items: {
        socials: ISocialLink[];
    };
}

interface IContactProps {
    className?: string;
    section: IContactSection;
    TitleTag?: React.ElementType;
}

const Contact: React.FC<IContactProps> = ({ className = '', TitleTag, section }) => {
    const {
        sectionId,
        content: { texts, media, contactInfo },
        css,
        style,
    } = section;

    const { formikRef, handleSubmit, errorMessage, isSuccess, isLoading } =
        usePostForm(postContactForm);
    const t = useTranslations('Contact');

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionContainer} ${className}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
        >
            {texts[5] && texts[6] && (
                <a className={contactLink} href={`tel:${texts[6]}`}>
                    {texts[5]}
                </a>
            )}
            {texts[3] && texts[4] && (
                <a className={contactLink} href={`mailto:${texts[4]}`}>
                    {texts[3]}
                </a>
            )}
            {media.length > 0 && <Image className={image} aspectRatio="1" media={media} />}
            {media.length === 0 && (
                <div className={contactInfoWrapper}>
                    {contactInfo.map((info) => (
                        <ContactInfo
                            key={`contact-info-${info.title}`}
                            title={info.title}
                            content={info.content}
                            className={contactInfoBlock}
                        />
                    ))}
                </div>
            )}
            <div className={formContainer}>
                {!isSuccess && (
                    <FormGenerator
                        name="ContactForm"
                        className={form}
                        formRef={formikRef}
                        formikProps={{ onSubmit: handleSubmit }}
                        fields={contactForm}
                        errorMessage={errorMessage}
                        extraFieldsProps={{ consentDescription: texts[7] }}
                        submitLoading={isLoading}
                    />
                )}
                {isSuccess && (
                    <div className={successInfoContainer}>
                        <Title Tag="h3" className={successTitle}>
                            {t.thankYouTitle}
                        </Title>
                        <Heart className={heartIcon} />
                        <p className={successContent}>{t.thankYouContent}</p>
                    </div>
                )}
            </div>
        </Section>
    );
};

export default Contact;
